import React from 'react';
import { Route as RouterRoute, RouteProps } from 'react-router-dom';

interface RouterRouteProps extends RouteProps {
  path: string;
  element: React.ReactElement;
  exact?: boolean;
}

/**
 * Route wrapper which makes the route component api compatible with react-router @ v6.
 * This makes it easier to type components.
 */
function Route({ path, element, ...props }: RouterRouteProps) {
  return (
    <RouterRoute {...props} path={path}>
      {element}
    </RouterRoute>
  );
}

export { Route };
