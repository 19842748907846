import * as Sentry from '@sentry/browser';
import { Integrations as ApmIntegrations } from '@sentry/apm';

const getEnvironment = () => {
  switch (window.location.host) {
    case 'widgets.oms.no':
      return 'Production';
    case 'widgets-test.oms.no':
      return 'Test';
    case 'widgets.apps.dev.oms.no':
      return 'Dev';
    default:
      return null;
  }
};

/*
function getReleaseVersion() {
  const { releaseVersion } = require('./meta.json') || {};
  return releaseVersion;
}
*/

export function setupSentry() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn:
        'https://08cea9bc95274669a0ad3389ed0f97d4@o352519.ingest.sentry.io/5921897',
      sampleRate: 0.25,
      environment: getEnvironment(),
      // release: getReleaseVersion(),
      integrations: [new ApmIntegrations.Tracing()],
      tracesSampleRate: 0.25,
    });
  }
}
