import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import { setupSentry } from './setupSentry';
import ensureIntlSetup from './utils/ensureIntlSetup';
import Application from './main';

if (process.env.NODE_ENV !== 'development') {
  setupSentry();
}

ensureIntlSetup().then(() => {
  ReactDOM.render(<Application />, document.getElementById('root'));
});

// Boots service worker. In case of emergency, change register to unregister
// below and re-deploy. This will kill the service workers. Do NOT simply remove
// this line or delete the public/service-worker.js file from the server,
// explicitly unregistring is required, anything else is a noop.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register({
//   // Override default behavior of waiting for all tabs to close before
//   // refreshing the cached files. The below callback will force a reload when
//   // a new service worker is detected.
//   //
//   // TODO Probably better to ask user first, example "A new version is
//   // available, reload now?"
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting;
//
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', event => {
//         if (event.target.state === 'activated') {
//           window.location.reload();
//         }
//       });
//       waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//     }
//   },
// });
serviceWorker.unregister();
