import { Helmet } from 'react-helmet';
import { useJaws } from '@oms/jaws-react';
import { formatNumber } from '@oms/utils';

// Using harcoded values as dark theme colors are not suitable
export const getChangeColor = (value: number) => {
  if (value > 0) return '#3e9e3e';
  if (value < 0) return '#e61e05';
  return '#939393';
};

interface QuoteFragmentProps {
  itemSector: string;
}

export const Quote = ({ itemSector }: QuoteFragmentProps) => {
  const { items, hasData } = useJaws({
    initiatorComponent: 'Quote',
    itemSector,
    columns: 'ITEM, SECTOR, CHANGE_PCT',
  });

  if (!hasData) return null;

  const ticker = items.first().get('ITEM');
  //   const sector = items.first().get('SECTOR');
  const change = items.first().get('CHANGE_PCT');

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Barlow:wght@600&display=swap"
          rel="stylesheet"
        />
        <style>{`
          body,
          #root,
          #root * {
            display: inline-block;
            white-space: nowrap;
          }
          body {
            margin: 0;
          }
        `}</style>
      </Helmet>
      <a
        href="/"
        target="_top"
        style={{
          color: getChangeColor(change),
          textDecoration: 'none',
          margin: 0,
          fontWeight: 600,
          fontSize: '18px',
        }}
      >
        {ticker}, {formatNumber(change)}%
      </a>
    </>
  );
};
