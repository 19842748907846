import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import { OMSContext } from '@oms/components-context';
import Jaws from '@oms/jaws';
import runHeightChangeWatcher from 'utils/runHeightChangeWatcher';
import { Route } from 'components/Route';
import { BASE_URL } from 'constants/Jaws';
import StockGraphFragment from 'components/na24/StockGraph';
import { Quote } from 'components/na24/Quote';

const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'));
const NHDMarketOverviewPage = React.lazy(() =>
  import('pages/nhd/MarketOverviewPage'),
);
const SPVSavingsCalculatorPage = React.lazy(() =>
  import('pages/spv/SavingsCalculatorPage'),
);
runHeightChangeWatcher();

const queryClient = new QueryClient();

const instance = new Jaws({
  url: BASE_URL,
  useWebSockets: localStorage.getItem('useWebSockets') === 'true',
});

const jaws = { instance };

const OMSProvider = ({ children }) => {
  return (
    <OMSContext
      jaws={jaws}
      language={navigator.language}
      components={{
        Link,
      }}
    >
      {children}
    </OMSContext>
  );
};

export default function Main() {
  return (
    <OMSProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={null}>
          <Router>
            <Switch>
              <Route
                path="/nhd/marketOverview"
                element={<NHDMarketOverviewPage />}
              />
              <Route
                path="/spv/savingsCalculator"
                element={<SPVSavingsCalculatorPage />}
              />
              <Route
                path="/na24/chart/:itemSector"
                render={props => (
                  <StockGraphFragment
                    itemSector={props?.match?.params?.itemSector || 'EQNR.OSE'}
                  />
                )}
              />
              <Route
                path="/na24/quote/:itemSector"
                render={props => (
                  <Quote
                    itemSector={props?.match?.params?.itemSector || 'EQNR.OSE'}
                  />
                )}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Switch>
          </Router>
        </Suspense>
      </QueryClientProvider>
    </OMSProvider>
  );
}
