import { InstrumentChart } from '@oms/components-instrument-chart';

import { getChartOptions } from './getChartOptions';
import { getItemFromItemSector } from '@oms/utils';

export const selectionLabels = {
  SELECTION_INTRADAY: '1D',
  SELECTION_WEEK: '1W',
  SELECTION_ONE_MONTH: '1M',
  SELECTION_THREE_MONTHS: '3M',
  SELECTION_SIX_MONTHS: '6M',
  SELECTION_ONE_YEAR: '1Y',
} as const;

interface StockGraphFragmentProps {
  itemSector: string;
  isDarkMode: boolean;
  initialTimeSelection: string;
}

export const StockGraphFragment = ({
  itemSector,
  isDarkMode,
}: StockGraphFragmentProps) => {
  const item = getItemFromItemSector(itemSector);

  if (!item) return null;

  return (
    <InstrumentChart
      itemSector={itemSector}
      enableStreaming={true}
      seriesColors={isDarkMode ? ['#fff'] : ['#000']}
      options={getChartOptions()}
    />
  );
};

export default StockGraphFragment;
