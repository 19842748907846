export const getChartOptions = (): Highcharts.Options => ({
  plotOptions: {
    line: {
      step: undefined,
    },
    series: {
      lineWidth: 1,
      states: {
        hover: {
          lineWidthPlus: 0,
        },
      },
    },
  },
  chart: {
    /** null = autosize based on height of container or fallback to 400px */
    height: null,
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  loading: {
    style: {
      position: 'absolute',
      backgroundColor: '#000',
      opacity: 0.1,
      textAlign: 'center',
    },
  },
  navigator: { enabled: false },
  yAxis: [
    {
      gridLineColor: 'rgb(34,55,88)',
      gridLineDashStyle: 'Dash',
      gridLineWidth: 1,
      labels: {
        align: 'left',
        x: 0,
        y: -2,
        style: { color: '#0071eb' },
      },
    },
  ],
  xAxis: {
    lineWidth: 0,
    tickLength: 0,
    labels: { style: { color: '#0071eb' } },
  },
});
